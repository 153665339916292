"use client";

import React, { useState } from "react";
import styles from "./ChallengeCard.module.scss";
import FormattedDate from "../FormattedDate";
import { ShareSheet } from "@/components/actions/ShareSheet";
import Link from "next/link";
import clsx from "clsx";
import { Trash } from "react-feather";
import { set } from "date-fns";
import { useSession } from "@/providers/Session";
import { wait } from "@/lib/helpers";
import { Share2 } from "lucide-react";
import ShareMenu from "./ShareMenu";
import DeleteConfirm from "./DeleteConfirm";
import { useChallenges } from "@/providers/Challenges";
import If from "@/components/common/If";

/**
 *
 * ChallengeCard
 *
 */
const ChallengeCard = ({
  challenge = {},
  onDelete = () => {},
  isLight = false,
  isThinknado = false,
  isIdea = false,
  ...props
}) => {
  const {
    setChallenge,
    setStream,
    setId,
    setTitle,
    setInputState,
    setChallengeData,
    setPersonality,
    setIsLoading,
  } = useSession();
  const { reloadChallenges } = useChallenges();
  const [state, setState] = useState("idle");

  return (
    <>
      <article
        className={clsx(
          styles["challenge-card"],
          "text-white p-4 challenge-card",
          "flex flex-col justify-between gap-2  max-w-full aspect-auto md:aspect-square min-h-[300px]",
          state === "deleting" && "opacity-25",
          isThinknado && "text-white",
          isIdea ? "bg-pink" : "bg-purple"
        )}
        {...props}
      >
        <Link
          href={
            isThinknado
              ? `/app/canvas/${challenge.id}`
              : `/app/challenges/${challenge.id}`
          }
          className="block  min-h-[75%]"
          // onClick={async () => {
          // setIsLoading(true);
          // await wait(200);
          // setChallenge?.(challenge);
          // setStream?.(challenge.stream);
          // setId?.(challenge.id);
          // setTitle?.(challenge.title);
          // setInputState?.(challenge.inputState);
          // setChallengeData({
          //   whatIfWord: challenge.whatIfWord,
          //   whatIfImage: challenge.whatIfImage,
          //   whatIfImageWord: challenge.whatIfImageWord,
          //   idea: challenge.idea,
          //   prompts: challenge.prompts,
          //   questions: challenge.questions,
          // });
          // setPersonality?.(challenge.personality);
          // await wait(200);
          // setIsLoading(false);
          // }}
        >
          <If condition={!isThinknado}>
            <div className="aspect-video w-full overflow-hidden">
              {challenge.whatIfImage ? (
                <img
                  src={`/cards/WHATIF_B_American-poker-size-${challenge.whatIfImage}.jpg`}
                  className="w-[100%] aspect-auto md:aspect-square object-cover transform rotate-90 h-[200%] top-[-25%] relative"
                />
              ) : (
                <div className="w-[100%] aspect-square bg-image-placeholder relative" />
              )}
            </div>
          </If>
          <div className="detail px-0 flex flex-col gap-4 pb-3 justify-start items-start text-current">
            <h2
              className={`text-xl font-bold ${
                !isLight ? "text-white" : "text-white"
              }`}
            >
              {challenge.challenge ||
                challenge.typedChallenge ||
                challenge.idea}
            </h2>
          </div>
        </Link>

        <div className="stack gap-2">
          <If condition={isThinknado}>
            <div className="flex items-center gap-8">
              <span className="text-sm text-current flex items-center gap-1 text-white font-light">
                Ideas {challenge._count.ideas}
              </span>
              <span className="text-sm text-current flex items-center gap-1 text-white font-light">
                Bets {challenge._count.bets}
              </span>
            </div>
          </If>
          <div className="px-0 pb-4 flex justify-between items-center relative z-10">
            <FormattedDate
              className={`opacity-50 ${!isLight ? "text-white" : "text-white"}`}
              date={challenge.createdAt}
            />
            <div className="flex items-center gap-2">
              {/* <button
            className="p-0"
            onClick={() => {
              const doIt = confirm(
                "Are you sure you want to delete this challenge? This cannot be undone."
              );
              if (doIt) {
                deleteChallenge(challenge.id);
              }
            }}
          >
            <Trash color="#1f9fbb" className="h-[22px]" />
          </button> */}
              <DeleteConfirm
                id={challenge.id}
                onDelete={(state) => {
                  setState(state);
                  onDelete(challenge.id);
                }}
              />
              <ShareMenu
                id={challenge.id}
                initialIsPublic={challenge.isPublic}
              />
              {/* <Link href={`/shared/${challenge.id}`}>
            <Share2 color="#1f9fbb" />
          </Link> */}
              {/* <ShareSheet color="text-[#1f9fbb]" /> */}
            </div>
          </div>
        </div>
        {state === "deleting" && <span>Deleting...</span>}
      </article>
    </>
  );
};

export default ChallengeCard;
